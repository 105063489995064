.App {
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  padding-top: 1vw;
  padding-bottom: 1vw;

}

nav img{
width: 50px;
margin-left: 20px;

}

.tab-bar {
  display: flex;
  margin-bottom: 20px;
  margin-top: 2vh;
  margin-right: 20px;
}

.tab-bar button {
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 10px 20px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
  border-radius: 8px;
}

.tab-bar button:last-child {
  margin-right: 0;
}

.tab-bar .active {
  background-color: #0098c3;
  color: #ffffff;
  border-color: #007bff;
}
