.Memefolio {
  text-align: center;
  background-color:  #14181b;
  width: 100%;
  color: #fffa65;
  height: 100vh;
}

.Memefolio-logo {
  object-fit: contain;
  max-width: 350px;
  justify-self: end;
}


.Memefolio-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 6vw;
  display: column;
  background-size: cover;
  background-position: center top;
}

.Memefolio-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
}

.Memefolio-text h1 {
  font-family: "SpaceMonoBold";
  font-weight: 300;
  line-height: 125%;
  color: #fffa65;
  font-size: 6vw;
  z-index: 1;
  text-align: center;
}

.Memefolio-text p{
  font-family: "SpaceMono";
  font-style: normal;
  font-size: 1.5vw;
  font-weight: 500;
  line-height: 125%;
  z-index: 1;
  text-align: center;
  width: 75%;
  color: #fffa65;
}

.Memefolio-text span{
  font-family: "SpaceMono";
  font-style: normal;
  font-size: 1vw;
  font-weight: 300;
  line-height: 125%;
  z-index: 1;
  text-align: center;

}

.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group input {
  font-family: "SpaceMono";
  border: 2px solid #4b4b4b;
  border-radius: 4px;
  padding: 10px;
  color: #282c34;
  font-size: 1rem;
  width: 40ch;
  text-align: center;
}

.input-group button {
  font-family: "SpaceMono";
  background-color: #fffa65;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #4b4b4b;
  font-size: 1rem;
  font-style: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.input-group button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.wallet-connection{
  position: absolute;
  top: 10px; /* Adjust top positioning as needed */
  right: 10px;
}

.wallet-connection-button{

  font-family: "SpaceMono";
  background-color: #fffa65;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #4b4b4b;
  font-size: 1rem;
  font-style: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.wallet-connection-success{
  font-family: "SpaceMono";
  background-color: #fffa65;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #4b4b4b;
  font-size: 1rem;
  font-style: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media screen and (min-width: 2000px) {
  .Memefolio {
  min-height: 125vh;
  }
}



@media screen and (max-width: 950px) {

  .Memefolio-header {
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 3vw;
    display: flex;
    flex-direction: column-reverse;
    height: 80vh;
  }

  .Memefolio-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vw;
  }
  .Memefolio-logo {
    object-fit: contain;
    max-width: 150px;
    justify-self: end;
  }

  .Memefolio-text h1 {
    font-size: 8vw;
    width: 75%;
    text-align: center;
  }
  
  .Memefolio-text p{
    font-size: 3vw;
    text-align: center;

  }
  
  .Memefolio-text span{
    font-size: 2vw;
    text-align: center;


  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-group input {
    font-family: "SpaceMono";
    border-radius: 4px;
    padding: 10px;
    font-size: 2vw;
  }
  
  .input-group button {
    font-family: "SpaceMono";
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 2vw;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .wallet-connection{
    position: absolute;
    top: 10px; /* Adjust top positioning as needed */
    right: 10px;
  }
  
  .wallet-connection-button{
    font-family: "SpaceMono";
    background-color: #fffa65;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: #4b4b4b;
    font-size: 2vw;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .wallet-connection-success{
    font-family: "SpaceMono";
    background-color: #fffa65;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: #4b4b4b;
    font-size: 1rem;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}

@media screen and (max-width: 526px) {
  .Memefolio-header {
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 0vw;
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
  }

  .Memefolio-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vw;
  }
  .Memefolio-logo {
    object-fit: contain;
    max-width: 150px;
    justify-self: end;
  }

  .Memefolio-text h1 {
    font-size: 10vw;
    width: 75%;
    text-align: center;
  }
  
  .Memefolio-text p{
    font-size: 5vw;
    text-align: center;

  }
  
  .Memefolio-text span{
    font-size: 3.2vw;
    text-align: center;

  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-group input {
    font-family: "SpaceMono";
    border-radius: 4px;
    padding: 10px;
    margin-right: 10px; /* Whitespace between text input and button */
    font-size: 3vw;
  }
  
  .input-group button {
    font-family: "SpaceMono";
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 3vw;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .wallet-connection{
    position: absolute;
    top: 10px; /* Adjust top positioning as needed */
    right: 10px;
  }
  
  .wallet-connection-button{
    font-family: "SpaceMono";
    background-color: #fffa65;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: #4b4b4b;
    font-size: 3vw;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .wallet-connection-success{
    font-family: "SpaceMono";
    background-color: #fffa65;
    border: none;
    border-radius: 4px;
    color: #4b4b4b;
    font-size: 3vw;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}