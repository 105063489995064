.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #14181b;
  width: 50%; /* Width of the modal */
  height: 75vh; /* Height of the modal */
  max-height: 75vh; /* Maximum height */
  overflow-y: auto; /* Make content scrollable */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: relative;
  font-family: "SpaceMono";
  text-align: start;
}

.transaction-header{

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: white;
}



.transaction-item {
  border: 2px solid;
  padding: 10px;
  margin-bottom: 10px;
  text-align: start;
  border-radius: 4px; /* Added border radius */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Space between elements */
}

.transaction-item:hover {
  padding: 15px;
  transition: .5s;


}

.transaction-detail {
  font-weight: bold;
}

.transaction-buy {
  border-color: #252b30; /* Green border for buy transactions */
  background-color: #376338;
}

.transaction-sell {
  border-color: #252b30; /* Red border for sell transactions */
  background-color: #633737;
}

@media screen and (min-width: 2000px) {
  .transaction-item span{
    font-size: .75vw;
  }
  .transaction-header h3{
    font-size: 1.2vw;

  }
  
}

@media screen and (max-width: 950px) {
    .transaction-item span{
      font-size: 2vw;
    }
  }

@media screen and (max-width: 526px) {
  .modal {
    width: 75%; /* Width of the modal */
    height: 75vh; /* Height of the modal */
    max-height: 75vh; /* Maximum height */
  }
  .transaction-item span{
    font-size: 2.5vw;
  }
}
