.portfolio {
  text-align: left; /* Aligning text to the left */
  font-family: "SpaceMono";
  padding: 2vw;
  background-color:  #14181b;

}

.portfolio span{
  font-size: 1vw;
}

.wallet-info {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center; /* Center aligning the wallet info */
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
} 

.portfolio-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border: 3px solid #252b30;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #14181b;
}


.item-logo {
  height: 100px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.item-details {
  display: flex;
  flex-direction: column;
  gap: 1.2vw;
  text-align: left;
  justify-content: space-around;
  width: 100%;

}

.item-details .image-header{

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;

}

.item-details .image-header h4 {
  font-size: 1.5vw;
}


.item-details .item-data-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1vw;;
}
.item-details .item-data-grid p{

}

.portfolio-button-group{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 1vw;
}

.bonkbot-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #fffa65;
  border-radius: 8px;
  background-color: #fffa65;
  font-family: "SpaceMonoBold";
  font-weight: 300;
  line-height: 125%;
  color: #14181b;
  z-index: 1;
  text-align: center;
  height: 100%;
  cursor: pointer;
}

.bonkbot-button:hover{
  background-color: #f9f8cb;
  border: 1px solid #f9f8cb;

}

.bonkbot-image{
  width: 50px;
  transition: 0.2s;
}

.bonkbot-image:hover {
  transform: scale(1.5);
}

.item-details .copy-item{

  width: 30px;
  height: 30px;
}


.bold {
  font-weight: bold;
}
@media screen and (min-width: 2000px) {
  .item-details .image-header h4 {
    font-size: 1.25vw;
  }
  .item-details .item-data-grid p{
    font-size: .8vw;
  }
  .bonkbot-button{
    font-size: .75vw;;
  }
  
  .bonkbot-button:hover{
    background-color: #f9f8cb;
    border: 1px solid #f9f8cb;
  
  }
  .bonkbot-image{
    width: 75px;
  }
}

@media screen and (max-width: 950px) {
  .portfolio-grid {
    grid-template-columns: repeat(3, 1fr); /* Adjust grid to 3 columns for tablets */
    gap: 15px; /* Slightly reduce gap */
  }

  .portfolio-item {
    padding: 8px; /* Adjust padding for better spacing */
  }

  .item-details p {
    font-size: 0.9rem; /* Adjust font size for readability */
  }
  .item-details .copy-item{

    width: 25px;
    height: 25px;
  }
  .item-details .image-header h4 {
    font-size: 2vw;
  }
  .item-details .item-data-grid p{
    font-size: 1.75vw;
  }
  .bonkbot-button{
    font-size: 1.5vw;;
  }
  .bonkbot-button:hover{
    background-color: #f9f8cb;
    border: 1px solid #f9f8cb;
  
  }
  .bonkbot-image{
    width: 40px;
  }
}

@media screen and (max-width: 526px) {
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr); /* Adjust grid to 2 columns for small phones */
    gap: 10px; /* Further reduce gap */
  }

  .portfolio-item {
    padding: 8px; /* Adjust padding for better spacing */
  }

  .item-details{
    gap: 4vw;
  }

  .item-details p {
    font-size: 2rem; /* Adjust font size for readability */
  }
  .item-details .copy-item{

    width: 25px;
    height: 25px;
  }
  .item-details .image-header h4 {
    font-size: 4vw;
  }
  .item-details .item-data-grid p{
    font-size: 3vw;
  }
  .portfolio-button-group{
    justify-content: start;
  }

  .bonkbot-button{
    font-size: 3vw;
  }
  .bonkbot-button:hover{
    background-color: #f9f8cb;
    border: 1px solid #f9f8cb;
  
  }
  .bonkbot-image{
    width: 40px;
  }
}