body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SpaceMono";
  src: local("SpaceMono"), url(../fonts/SpaceMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SpaceMonoBold";
  src: local("SpaceMonoBold"),
    url(../fonts/SpaceMono-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SpaceMonoBoldItalic";
  src: local("SpaceMonoBoldItalic"),
    url(../fonts/SpaceMono-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "SpaceMonoItalic";
  src: local("SpaceMonoItalic"),
    url(../fonts/SpaceMono-Italic.ttf) format("truetype");
}
