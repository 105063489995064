
.how-to-use-container {
  height: 100%;

}

.carousel {
    position: relative;
    overflow: hidden;
    height: 100%;

  }
  
  .carousel-inner {
    transition: transform 0.5s ease; /* Smooth transition for slide animation */
    color: #fffa65;
    height: 100%;

  }
  
  .carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    flex: 0 0 100%; /* Each slide takes up full width */
    padding: 20px;
    box-sizing: border-box;
    height: 100%;

  }


  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .cookie-button{
    font-family: "SpaceMono";
    background-color: #fffa65;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: #4b4b4b;
    font-size: 1rem;
    font-style: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 50%;
  }
  

  @media screen and (max-width: 950px) {
    .how-to-use-container .carousel .carousel-inner .carousel-item h1{
      font-size: 3vw;
    }
    .how-to-use-container .carousel .carousel-inner .carousel-item p{
      font-size: 1.75vw;
    }
  }
  
  @media screen and (max-width: 526px) {
    .how-to-use-container .carousel .carousel-inner .carousel-item h1{
      font-size: 5vw;
    }
    .how-to-use-container .carousel .carousel-inner .carousel-item p{
      font-size: 3vw;
    }
  }